import React, { useContext } from 'react';
import { Modal, Buttons, Button } from '@tymate/margaret';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router';
import { FirebaseContext } from 'contexts';
import * as Yup from 'yup';
import TextField from 'components/TextField';

const CreateCompany = () => {
  const firebase = useContext(FirebaseContext);
  const history = useHistory();

  const handleSubmit = async values => {
    const db = firebase.firestore();

    const company = await db.collection('companies').add({
      ...values,
      users: [firebase.auth().currentUser.uid],
    });
    history.push(`/companies/${company.id}`);
  };

  return (
    <Modal isOpen>
      <h2>Create company</h2>
      <Formik
        initialValues={{ name: '' }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <TextField label="Name" name="name" />
          <Buttons hasTopMargin alignX="center">
            <Button variant="primary" type="submit">
              Create
            </Button>
          </Buttons>
        </Form>
      </Formik>
    </Modal>
  );
};

export default CreateCompany;
