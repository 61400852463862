import React, { useContext, useRef, useLayoutEffect } from 'react';
import { useHistory } from 'react-router';
import { FirebaseContext } from 'contexts';

const Auth = () => {
  const firebase = useContext(FirebaseContext);
  const history = useHistory();
  const uiRef = useRef();
  const containerRef = useRef();

  useLayoutEffect(() => {
    uiRef.current = window.authUi;
    uiRef.current.start(containerRef.current, {
      signInOptions: [
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          history.push('/');
        },
      },
    });
  }, [firebase, history]);

  return <div ref={containerRef} />;
};

export default Auth;
