import React from 'react';
import styled from 'styled-components';
import FullPageLayout from 'ui/FullPageLayout';

const Content = styled.div`
  background-color: #fff;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  p {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
    margin-bottom: 0;
  }
`;

const Section = styled.section``;

const PrivacyPolicy = () => {
  return (
    <FullPageLayout size="medium" alignX="flex-start">
      <h1>Tymate Reviews Service</h1>
      <h2>Privacy Policy</h2>
      <Content>
        <Section>
          <p>
            Tymate operates the Tymate Reviews Service, a Facebook app allowing
            retrieval of linked Facebook Page reviews.
          </p>
          <p>
            This page is used to inform website visitors regarding our policies
            with the collection, use, and disclosure of Personal Information if
            anyone decided to use our Service, the Tymate Reviews Service
            website.
          </p>
          <p>
            If you choose to use our Service, then you agree to the collection
            and use of information in relation with this policy. The Personal
            Information that we collect are used for providing and improving the
            Service. We will not use or share your information with anyone
            except as described in this Privacy Policy.
          </p>
        </Section>
        <Section>
          <h3>Information Collection and Use</h3>
          <p>
            This app uses user accounts for authentication purposes. Your email
            address retrieved from Facebook or Google logins is stored alongside
            your personnal user ID.
          </p>
          <p>
            These personal informations may be used to contact you or provide
            you with support.
          </p>
        </Section>
        <Section>
          <h3>Log Data</h3>
          <p>
            We want to inform you that whenever you visit our Service, we
            collect information that your browser sends to us that is called Log
            Data. This Log Data may include information such as your computer’s
            Internet Protocol ("IP") address, browser version, pages of our
            Service that you visit, the time and date of your visit, the time
            spent on those pages, and other statistics.
          </p>
        </Section>
        <Section>
          <h3>Cookies</h3>
          <p>
            Cookies are files with small amount of data that is commonly used an
            anonymous unique identifier. These are sent to your browser from the
            website that you visit and are stored on your computer’s hard drive.
          </p>
          <p>
            Our website uses these "cookies" to collection information and to
            improve our Service. You have the option to either accept or refuse
            these cookies, and know when a cookie is being sent to your
            computer. If you choose to refuse our cookies, you may not be able
            to use some portions of our Service.
          </p>
          <p>
            For more general information on cookies, please read{' '}
            <a href="https://www.cookieconsent.com/what-are-cookies/">
              "What Are Cookies"
            </a>
            .
          </p>
        </Section>
        <Section>
          <h3>Service Providers</h3>
          <p>
            We may employ third-party companies and individuals due to the
            following reasons:
          </p>
          <ul>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>
          <p>
            We want to inform our Service users that these third parties have
            access to your Personal Information. The reason is to perform the
            tasks assigned to them on our behalf. However, they are obligated
            not to disclose or use the information for any other purpose.
          </p>
        </Section>
        <Section>
          <h3>Changes to This Privacy Policy</h3>
          <p>
            We may update our Privacy Policy from time to time. Thus, we advise
            you to review this page periodically for any changes. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            These changes are effective immediately, after they are posted on
            this page.
          </p>
        </Section>
        <Section>
          <h3>Owner and Data Controller</h3>
          <p>82 rue Winston Churchill, 59160 Lille</p>
          <p>
            <b>Owner contact email:</b> dev@tymate.com
          </p>
        </Section>
      </Content>
    </FullPageLayout>
  );
};

export default PrivacyPolicy;
