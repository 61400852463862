import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { Stack } from '@tymate/margaret';
import { getPageData } from 'api/pages';
import { API_BASE } from '../constants';

const fetchPageData = (key, { companyId }) =>
  getPageData({ companyId }).then(({ data }) => data);

const Response = styled.textarea`
  width: 100%;
  min-height: 400px;
  outline: none;
  resize: none;
  margin-top: ${({ theme }) => theme.spacing()};
`;

const RawData = ({ companyId }) => {
  const { data } = useQuery(['pageData', { companyId }], fetchPageData);
  console.log('data', data);

  return (
    <Stack direction="column" gutterSize={0.25}>
      <div>
        <b>Data URL</b>
      </div>
      <div>
        {API_BASE}/companies/{companyId}
      </div>
      <Response readOnly value={JSON.stringify(data, 2, 2)} />
    </Stack>
  );
};

export default RawData;
