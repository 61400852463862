import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { Button } from '@tymate/margaret';
import { FirebaseContext, AuthContext } from 'contexts';
import FullPageLayout from 'ui/FullPageLayout';
import Auth from 'components/Auth';
import logo from 'images/logo.svg';

const AuthProvider = ({ children }) => {
  const firebase = useContext(FirebaseContext);
  const history = useHistory();
  const [user, setUser] = useState();
  const [token, setToken] = useState();

  useEffect(() => {
    const auth = firebase.auth();

    auth.onAuthStateChanged(async user => {
      if (!user) {
        history.push('/login');
        return;
      }
      const token = await user.getIdToken();
      setUser(user);
      setToken(token);
    });
  }, [firebase, history]);

  const onLogout = () => {
    const auth = firebase.auth();
    window.FB.logout();
    auth.signOut();
  };

  return (
    <AuthContext.Provider value={{ user, token, onLogout }}>
      <Switch>
        <Route path="/login">
          <FullPageLayout>
            <img src={logo} alt="Tymate" />
            <h1>Reviews service</h1>
            <Auth />
            <Button to="/privacy-policy">Privacy Policy</Button>
          </FullPageLayout>
        </Route>
        <Route>{children}</Route>
      </Switch>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
