const db = window.firebase.firestore();

export const fetchCompany = (key, { id }) =>
  db
    .collection('companies')
    .doc(id)
    .get()
    .then(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

export default db;
