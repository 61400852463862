import React from 'react';
import { FirebaseContext } from '../contexts';

const FirebaseProvider = ({ children }) => {
  return (
    <FirebaseContext.Provider value={window.firebase}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
