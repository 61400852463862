import React, { useContext, useState, useMemo } from 'react';
import styled from 'styled-components';
import { find } from 'lodash';
import { useQuery, queryCache } from 'react-query';
import Select from 'react-select';
import db, { fetchCompany } from 'db';
import { FacebookContext, FirebaseContext, AuthContext } from 'contexts';
import { Buttons, Button } from '@tymate/margaret';
import { linkPage } from 'api/pages';

const PageSelector = styled.div`
  min-height: 100px;
`;

const fetchPermissions = (key, { accessToken }) => {
  if (!accessToken) {
    return;
  }
  return new Promise(resolve => window.FB.api('/me/permissions', resolve));
};

const fetchAccounts = (key, { accessToken }) => {
  if (!accessToken) {
    return;
  }
  return new Promise(resolve => window.FB.api('/me/accounts', resolve));
};

const FacebookPageSelector = ({ companyId }) => {
  const [selectedPage, setSelectedPage] = useState();
  const [isLinking, setIsLinking] = useState();
  const { status, authResponse, refreshLoginStatus } = useContext(
    FacebookContext,
  );
  const firebase = useContext(FirebaseContext);
  const { token } = useContext(AuthContext);

  const { data: company, isLoading } = useQuery(
    ['company', { id: companyId }],
    fetchCompany,
  );

  const { data: permissions, isLoading: isLoadingPermissions } = useQuery(
    ['facebookPermissions', { accessToken: authResponse?.accessToken }],
    fetchPermissions,
  );

  const hasPermissions = useMemo(() => {
    if (!permissions || !permissions?.data) {
      return false;
    }
    return Boolean(
      find(permissions.data, {
        permission: 'pages_read_user_content',
        status: 'granted',
      }),
    );
  }, [permissions]);

  const { data: accounts } = useQuery(
    ['facebookAccounts', { accessToken: authResponse?.accessToken }],
    fetchAccounts,
  );

  const handleLogin = () => {
    window.FB.login(refreshLoginStatus, { scope: 'pages_read_user_content' });
  };

  const handleLink = async () => {
    setIsLinking(true);
    await linkPage({
      token,
      companyId,
      accessToken: authResponse?.accessToken,
      pageId: selectedPage,
    });
    setIsLinking(false);
    queryCache.invalidateQueries('company');
    queryCache.invalidateQueries('pageData');
  };

  const handleUnlink = async () => {
    const company = db.collection('companies').doc(companyId);
    await company.update({
      facebookPageId: firebase.firestore.FieldValue.delete(),
      facebookPageToken: firebase.firestore.FieldValue.delete(),
    });
    queryCache.invalidateQueries('company');
  };

  if (isLoading || isLoadingPermissions || !status) {
    return null;
  }

  if (company?.facebookPageId) {
    return (
      <>
        <p>
          <b>Current page ID</b> : {company?.facebookPageId}
        </p>
        <Buttons>
          <Button variant="secondary" onClick={handleUnlink}>
            Unlink
          </Button>
        </Buttons>
      </>
    );
  }

  return (
    <PageSelector>
      {status !== 'connected' || !hasPermissions ? (
        <Button variant="primary" onClick={handleLogin}>
          {status !== 'connected'
            ? 'Log in with Facebook'
            : 'Grant permissions'}
        </Button>
      ) : (
        <>
          <Select
            options={(accounts?.data || []).map(account => ({
              value: account.id,
              label: account.name,
            }))}
            onChange={({ value }) => setSelectedPage(value)}
          />
          <Buttons hasTopMargin>
            <Button
              variant="primary"
              onClick={handleLink}
              isLoading={isLinking}
            >
              Associate page
            </Button>
          </Buttons>
        </>
      )}
    </PageSelector>
  );
};

export default FacebookPageSelector;
