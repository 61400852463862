import React, { useEffect, useState } from 'react';
import { FacebookContext } from 'contexts';

const FacebookLoginProvider = ({ children }) => {
  const [auth, setAuth] = useState();

  useEffect(() => {
    fetchStatus();
  }, []);

  const fetchStatus = () => {
    window.FB.getLoginStatus(result => {
      setAuth(result);
    });
  };

  return (
    <FacebookContext.Provider
      value={{
        status: auth?.status,
        authResponse: auth?.authResponse,
        refreshLoginStatus: fetchStatus,
      }}
    >
      {children}
    </FacebookContext.Provider>
  );
};

export default FacebookLoginProvider;
