import React from 'react';
import { Switch, Route } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthProvider from 'providers/AuthProvider';
import FacebookLoginProvider from 'providers/FacebookLoginProvider';
import Companies from 'containers/Companies';
import Company from 'containers/Company';
import PrivacyPolicy from 'containers/PrivacyPolicy';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route
          path="/"
          render={() => (
            <AuthProvider>
              <FacebookLoginProvider>
                <Switch>
                  <Route path="/companies/:id" component={Company} />
                  <Route path="/" component={Companies} />
                </Switch>
              </FacebookLoginProvider>
            </AuthProvider>
          )}
        />
      </Switch>
    </Router>
  );
};

export default App;
