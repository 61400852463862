import React from 'react';
import { Field } from 'formik';
import { Input } from 'ui/forms';

const TextField = ({ label, ...props }) => {
  return (
    <Field {...props}>
      {({ field }) => (
        <>
          <label>{label}</label>
          <Input {...field} />
        </>
      )}
    </Field>
  );
};

export default TextField;
