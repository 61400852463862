import React, { useContext } from 'react';
import { Button, Stack } from '@tymate/margaret';
import styled from 'styled-components';
import { AuthContext } from 'contexts';

const HeaderWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing()};

  background-color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderCompany = styled.h2`
  margin: 0;
`;
const HeaderInfo = styled.div``;

const Header = ({ company }) => {
  const { onLogout } = useContext(AuthContext);

  return (
    <HeaderWrapper>
      <HeaderCompany>{company?.name}</HeaderCompany>
      <Stack alignY="center" gutterSize={1}>
        <HeaderInfo>ID : {company?.id}</HeaderInfo>
        <Button
          variant="outline"
          onClick={e => {
            e.preventDefault();
            onLogout();
          }}
        >
          Log out
        </Button>
      </Stack>
    </HeaderWrapper>
  );
};

export default Header;
