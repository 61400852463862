import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { getPageData } from 'api/pages';
import { Stack } from '@tymate/margaret';

const StatCount = styled.div`
  font-size: 32px;
  color: ${({ theme }) => theme.secondary};
`;

const StatLabel = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const fetchPageData = (key, { companyId }) =>
  getPageData({ companyId }).then(({ data }) => data);

const PageReviews = ({ companyId }) => {
  const { data } = useQuery(['pageData', { companyId }], fetchPageData);
  return (
    <Stack direction="column" gutterSize={1}>
      <Stack style={{ width: '100%' }} alignX="space-between">
        <StatLabel>Fans</StatLabel>
        <StatCount>{data?.fanCount}</StatCount>
      </Stack>
      <Stack style={{ width: '100%' }} alignX="space-between">
        <StatLabel>Overall Rating</StatLabel>
        <StatCount>{data?.overallStarRating}</StatCount>
      </Stack>
    </Stack>
  );
};

export default PageReviews;
