import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { getPageData } from 'api/pages';
import { Stack } from '@tymate/margaret';
import { MdStar } from 'react-icons/md';
import { format, parseISO } from 'date-fns';

const StatCount = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  color: ${({ theme }) => theme.secondary};
  svg {
    margin-left: ${({ theme }) => theme.spacing(0.25)};
  }
`;

const ReviewWrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.02);
  padding: ${({ theme }) => theme.spacing()};
  align-items: center;

  > * + * {
    margin-left: ${({ theme }) => theme.spacing()};
  }
`;

const ReviewText = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(0.25)};
`;

const ReviewMeta = styled.div`
  color: ${({ theme }) => theme.textLight};
`;

const fetchPageData = (key, { companyId }) =>
  getPageData({ companyId }).then(({ data }) => data);

const Review = ({ review }) => {
  return (
    <ReviewWrapper>
      <StatCount>
        {review.rating} <MdStar />
      </StatCount>
      <div>
        {review?.reviewText && <ReviewText>{review.reviewText}</ReviewText>}
        <ReviewMeta>
          {review?.recommendationType} &middot;{' '}
          {review?.createdTime &&
            format(parseISO(review.createdTime), 'MM/dd/yyyy')}
        </ReviewMeta>
      </div>
    </ReviewWrapper>
  );
};

const PageReviews = ({ companyId }) => {
  const { data } = useQuery(['pageData', { companyId }], fetchPageData);
  const reviews = data?.ratings?.data || [];

  return (
    <Stack direction="column" gutterSize={1}>
      {reviews.map(review => (
        <Review review={review} />
      ))}
    </Stack>
  );
};

export default PageReviews;
