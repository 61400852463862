import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import {
  Container,
  Card as RawCard,
  CardHeader,
  CardTitle,
  CardContent,
  media,
} from '@tymate/margaret';
import Header from 'components/Header';
import FacebookPageSelector from 'components/FacebookPageSelector';
import PageReviews from 'components/PageReviews';
import LatestReviews from 'components/LatestReviews';
import RawData from 'components/RawData';
import { fetchCompany } from 'db';

const CompanySections = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
  `};
`;

const Card = styled(RawCard)`
  background-color: #fff;
`;

const Company = ({ match }) => {
  const {
    params: { id },
  } = match;
  const { data: company } = useQuery(['company', { id }], fetchCompany);

  return (
    <>
      <Header company={company} />
      <Container>
        <CompanySections>
          <Card style={{ overflow: 'visible' }}>
            <CardHeader>
              <CardTitle>Facebook Page</CardTitle>
            </CardHeader>
            <CardContent>
              <FacebookPageSelector companyId={id} />
            </CardContent>
          </Card>
          {company?.facebookPageToken && (
            <>
              <Card>
                <CardHeader>
                  <CardTitle>Reviews summary</CardTitle>
                </CardHeader>
                <CardContent>
                  <PageReviews companyId={id} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle>Latest Reviews</CardTitle>
                </CardHeader>
                <CardContent>
                  <LatestReviews companyId={id} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle>API data</CardTitle>
                </CardHeader>
                <CardContent>
                  <RawData companyId={id} />
                </CardContent>
              </Card>
            </>
          )}
        </CompanySections>
      </Container>
    </>
  );
};

export default Company;
