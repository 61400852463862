import React from 'react';
import styled from 'styled-components';
import { Container, Stack } from '@tymate/margaret';

const Main = styled(Stack)`
  min-height: 90vh;
`;

const FullPageLayout = ({ children, size = 'narrow', alignX = 'center' }) => {
  return (
    <Container size={size}>
      <Main size="full" alignX={alignX} alignY="center" direction="column">
        {children}
      </Main>
    </Container>
  );
};

export default FullPageLayout;
