import React, { useContext } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { Stack, Card, Buttons, Button } from '@tymate/margaret';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import FullPageLayout from 'ui/FullPageLayout';
import CreateCompany from 'components/CreateCompany';
import { FirebaseContext, AuthContext } from 'contexts';
import logo from 'images/logo.svg';
import db from 'db';

const CompanyList = styled(Stack)`
  width: 100%;

  > * {
    width: 100%;
    text-decoration: none;
    color: ${({ theme }) => theme.text};
  }

  > * + * {
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;

const fetchCompanies = (key, { userId }) => {
  return db
    .collection('companies')
    .where('users', 'array-contains', userId)
    .get()
    .then(result => result.docs);
};

const Companies = () => {
  const firebase = useContext(FirebaseContext);
  const { onLogout } = useContext(AuthContext);
  const userId = firebase.auth().currentUser?.uid;
  const { data: companies = [] } = useQuery(
    ['companies', { userId }],
    fetchCompanies,
  );

  return (
    <FullPageLayout>
      <img src={logo} alt="Tymate" />
      <h1>Reviews service</h1>
      <Route path="/create">
        <CreateCompany />
      </Route>
      <CompanyList direction="column">
        {companies.map(company => (
          <Link to={`/companies/${company.id}`}>
            <Card variant="link" title={company.data().name}></Card>
          </Link>
        ))}
      </CompanyList>
      <Buttons>
        <Button variant="primary" to="/create">
          Create company
        </Button>
      </Buttons>
      <Buttons>
        <Button
          onClick={e => {
            e.preventDefault();
            onLogout();
          }}
        >
          Log out
        </Button>
        <Button to="/privacy-policy">Privacy Policy</Button>
      </Buttons>
    </FullPageLayout>
  );
};

export default Companies;
